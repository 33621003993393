<template>
  <b-container class="mb-5">
    <b-row>
      <b-col>
        <b>Match Status Filter:</b>
        <b-form-select
          v-model="status"
          class="m-4"
          style="width: 600px"
          :options="statuses"
        />
        <span>
          <b>Counter:</b> {{ filteredMatches.length }}
        </span>
      </b-col>
      <b-col>
        <b>Filtering by Recruiter: </b>
        <b-form-select
          v-model="recruiterName"
          class="m-1 mr-4"
          style="width: 600px"
          :options="recruiters"
        />
        <span>
          <b>Counter:</b> {{ recruiters.length }}
        </span>
      </b-col>
      <b-col>
        <b>Filtering by Agency: </b>
        <b-form-select
          v-model="agencyName"
          class="m-3 mr-4"
          style="width: 600px"
          :options="agencies"
        />
        <span>
          <b>Counter:</b> {{ agencies.length }}
        </span>
      </b-col>
    </b-row>
    <b-card
      v-for="match in filteredMatches"
      :key="match.id"
      class="m-1"
    >
      <b-row>
        <b-col
          class="mt-2"
          cols="1"
        >
          <img
            v-if="match.project.business.logo"
            :src="match.project.business.logo"
            alt="Business logo"
            class="img-fluid logo"
          >
        </b-col>
        <b-col
          class="m-1 d-inline-flex"
        >
          <b-col
            cols="6"
            class="m-1"
          >
            <p
              v-if="match.recruiter"
              class="mb-0"
            >
              <b>Match Name:</b> {{ match.recruiter.user.full_name }} {{ match.project.business.name }} {{ match.project.title }}
            </p>
            <p
              v-if="match.agency"
              class="mb-0"
            >
              <b>Match Name:</b> {{ match.agency.name }} {{ match.project.business.name }} {{ match.project.title }}
            </p>
            <p class="mb-0">
              <b>Client:</b> {{ match.project.business.name }}
            </p>
            <p class="mb-0">
              <b>Project:</b> {{ match.project.title }}
            </p>
          </b-col>
          <b-col>
            <p class="mb-0">
              <b>Match Status:</b> <u>{{ match.status }}</u>
            </p>
            <p
              v-if="match.recruiter"
              class="mb-0"
            >
              <b>Recruiter:</b> {{ match.recruiter.user.full_name }}
            </p>
            <p
              v-if="match.agency"
              class="mb-0"
            >
              <b>Agency:</b> {{ match.agency.name }}
            </p>
          </b-col>
          <b-col
            md="2"
            sm="4"
            cols="4"
          >
            <img
              v-if="match.recruiter"
              :src="match.recruiter.user.profile_picture"
              alt="Profile pic"
              class="img-fluid profile-pic"
            >
            <img
              v-if="match.agency"
              :src="match.agency.logo"
              alt="Profile pic"
              class="img-fluid profile-pic"
            >
          </b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="mb-0 h9">
            <b>Client Feedback:</b>{{ match.business_feedback }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import { MATCH_STATUSES, statusToArray } from '@/helpers/statuses'

export default {
  name: 'MatchesList',
  data () {
    return {
      matches: [],
      recruiters: [],
      agencies: [],
      agencyName: '',
      recruiterName: '',
      status: null,
      statuses: [
        {
          value: null,
          text: 'All',
        },
        ...statusToArray(MATCH_STATUSES),
      ],
    }
  },
  computed: {
    filteredMatches () {
      let matches = this.matches
      const recruiterName = this.recruiterName.toLocaleLowerCase()
      const agencyName = this.agencyName.toLocaleLowerCase()
      const status = (this.status || '').toLowerCase()
      if (status) {
        console.log(matches)
        matches = matches.filter(m => (m.status || '').toLowerCase() === status)
      }
      if (recruiterName || agencyName) {
        matches = matches.filter(m => {
          let name = ''
          if (m.recruiter?.user?.full_name) {
            name = m.recruiter.user.full_name
          } else if (m.agency?.name) {
            name = m.agency.name
          }
          if (name.toLocaleLowerCase().includes(recruiterName || agencyName)) {
            return true
          }
          return false
        })
      }
      return matches
    },
  },
  created () {
    this.getMatches()
    this.getRecruiters()
    this.getAgencies()
  },
  methods: {
    getMatches () {
      axios.get('/admin/v2/matches')
        .then(({ data }) => {
          this.matches = data
        })
    },
    getRecruiters () {
      return axios.get('/admin/v2/recruiters')
        .then(({ data }) => {
          let temp = data.map(recruiter => recruiter.user.full_name)
          temp = temp.sort((a, b) => a.localeCompare(b))
          this.recruiters = temp
        })
    },
    getAgencies () {
      return axios.get('/admin/v2/agencies')
        .then(({ data }) => {
          let temp = data.map(agency => agency.name)
          temp = temp.sort((a, b) => a.localeCompare(b))
          this.agencies = temp
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .profile-pic {
  border-radius: 10%;
}
</style>
